/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-main-color {
    border-radius: 5px !important;
    background-color: #30A9DE !important;
    color: #fff !important;
    border: 1px solid transparent !important;
}
.btn-border-main {
    border-radius: 5px !important;
    background-color: #fff !important;
    color: #30A9DE !important;
    border: 1px solid #30A9DE !important;
}
.btn-border-danger {
    text-align: center;
    border-radius: 5px !important;
    background-color: #fff !important;
    color: #EF5772 !important;
    border: 1px solid #EF5772 !important;
}
.btn-cancel {
    border-radius: 5px !important;
    background-color: #8c8c8c !important;
    color: #fff !important;
    border: 1px solid transparent !important;
}
.btn-danger {
    border-radius: 5px !important;
    background-color: #EF5772 !important;
    color: #fff !important;
    border: 1px solid transparent !important;
}
.border-main-color {
    border: 1px solid #30A9DE !important;
}
.background-main-color {
    background-color: #30A9DE;
}
.text-main-color {
    color: #30A9DE !important;
}
.text-danger-color {
    color: #EF5772  !important;
}
.text-green-color {
    color: green;
}
.text-border-color {
    color: #c4c4c4;
}
h6 {
    font-size: 18px;
}
.svg-back-arrow {
    position: absolute;
    left: 2px;
    top: 7px;
}
.bold-font {
    font-weight: 800;
}

.modal-dialog {
    max-width: 800px !important;
}
.modal-title {
    font-weight: 700;
    color: #30A9DE;
}

.table-loading-container {
    width: 100%;
    padding: 30px;
    text-align: center;
}

.pagination {
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
}

.tool-tip-container {
    max-width: 500px;
    text-align: left;
    line-height: 16px;
}

.quick-emergency {
    color: red;
    font-weight: 700;
}

.loader {
    margin: 100px auto;
    font-size: 18px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7), -1.8em -1.8em 0 0em #000000;
    }
}

@keyframes load5 {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #000000, 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.5), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.7), 1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.5), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7), 2.5em 0em 0 0em #000000, 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5), 2.5em 0em 0 0em rgba(0, 0, 0, 0.7), 1.75em 1.75em 0 0em #000000, 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.5), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7), 0em 2.5em 0 0em #000000, -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2), -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5), 0em 2.5em 0 0em rgba(0, 0, 0, 0.7), -1.8em 1.8em 0 0em #000000, -2.6em 0em 0 0em rgba(0, 0, 0, 0.2), -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.5), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7), -2.6em 0em 0 0em #000000, -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.2), 1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2), 2.5em 0em 0 0em rgba(0, 0, 0, 0.2), 1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2), 0em 2.5em 0 0em rgba(0, 0, 0, 0.2), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5), -2.6em 0em 0 0em rgba(0, 0, 0, 0.7), -1.8em -1.8em 0 0em #000000;
    }
}

/* Content */
.home-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    padding-top: 57px;
}

.content {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f4f5f6 50%, #fff 50%);
}
.content-wrapper {
    display: flex;
    flex-direction: row;
    width: 1000px;
    margin: 0 auto;
}
.content-menu {
    width: 17%;
    min-height: 700px;
    height: 100%;
    background-color: #f4f5f6;
    padding-top: 30px;
    padding-left: 22px;
}

.content-content {
    width: 83%;
    background-color: #fff;
}

.content-content .study-subject-container {
    padding-left: 30px;
}

/* Footer */
.router-wrapper {
    min-height: 100vh;
    position: relative;
    padding-bottom: 194px;
    display: flex;
    height: 100%;
}
.footer {
    position: absolute;
    bottom: 0;
    padding: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    background-color: #333333;
    color: #ffffff
}

.opacity-60 {
    opacity: 0.6;
}
.opacity-40 {
    opacity: 0.4;
}
/* responsive */

/* @media (max-width: 1200px) {
    .container {
        max-width: 375px;
    }
} */

/* admin case order */
.my-subject-container {
    width: 720px;
    margin: 0 auto;
    padding: 28px 40px 36px;
    background-color: white;
    border-radius: 5px;
}
.my-subject-box-header {
    display: flex;
    justify-content: center;
    padding: 10px;
}
.my-subject-title {
    text-align: left;
    font-size: 14px;
    font-weight: 300;
}
.my-subject-title div {
    color: #0075be;
    font-size: 28.75px;
    font-weight: 700;
}
.my-subject-header-info {
    padding: 15px;
    display: flex;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
}
.my-subject-info-title {
    font-weight: 600;
    margin-left: 10px;
    margin-right: 50px;
}
.my-subject-info-check {
    font-size: 14px;
    border-left: 1px solid #e6e6e6;
    height: 40%;
    padding-left: 20px;
}
.my-subject-check-box {
    display: flex;
    align-items: center;
}
.my-subject-check-box svg {
    margin-right: 10px;
    color: #0075be;
}
.my-subject-line {
    width: 100%;
    background: #0075be;
    height: 2px;
    margin-bottom: 15px;
    opacity: 70%;
}
.my-subject-box {
    text-align: center;
}
.my-subject-link {
    font-size: 14px;
    color:#10B6B0;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
}